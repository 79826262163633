const Recipes = () => {

  return (
    <div>
      <h1>Recipes</h1>

    </div>
  )

}
export default Recipes;